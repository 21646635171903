import request from '@/utils/request/index'
export default{
    namespaced:true,
    state:{
        company_info:{}
    },
    mutations:{
        SET_COMPANY_INFO(state,data){
            state.company_info = data
        }
    },
    actions:{
        // 开发者控制台首页
        async getIndexInfo(context, params){
            let res = await request({
                url:'/openPlatform/openPlatformHome/platformHome',
                type:"get",
                params,
            })
            if(res){
                context.commit('SET_COMPANY_INFO',res.result)
            }
            
            return res
        },
        // 我的应用
        async myApplication(context, params){
            let res = await request.get(`/openPlatform/openPlatformHome/myApplication`,{params})
            return res
        },
        // 编辑/添加应用
        async createCompileApplication(context, params){

            let res = await request({
                url:'/openPlatform/openPlatformHome/compileApplication',
                method:'post',
                headers:{
                    submitEmpty:true
                },
                data:params
            })
            return res
        },
        // 重置应用
        async resetApplication(context, params){
            let res = await request.get('/openPlatform/openPlatformHome/resetApplication',{params})
            return res
        },
        // 签署流程查询
        async signingProcessQuery(context, params){
            let res = await request.get('/openPlatform/openPlatformHome/signingProcessQuery',{params})
            return res
        },
        
        // 全部企业
        async allEnterprise(context, params){
            let res = await request({
                type:'get',
                url:'/openPlatform/openPlatformHome/allUserEnterprise',
                params
            })
            return res
        },
        // 选择主体(切换企业)
        async selectMainBody(context, params){
            let res = await request.post('/openPlatform/openPlatformHome/selectMainBody',params)
            return res
        },
    }
}