import request from '@/utils/request/index'
export default{
    namespaced:true,
    actions:{
        //创建工单
        async addWorder(context, params){
            let res = await request.post('/openPlatform/openPlatformHome/add',params)
            return res
        },
        // 我的工单
        async getMyOrderList(context, params){
            let res = await request({
                url:'/openPlatform/openPlatformHome/list',
                type:'get',
                headers:{
                    submitEmpty:false
                },
                params
            })
            return res
        },
        // 工单详情
        async getOrderDetail(context, params){
            let res = await request.get('/openPlatform/openPlatformHome/queryById',{params})
            return res
        },
        // 工单回复
        async workOrderMessage(context, params){
            let res = await request.post('/openPlatform/openPlatformHome/workOrderMessage',params)
            return res
        },
        //关闭或重启工单
        async closeOrRestart(context, params){
            let res = await request.post('/openPlatform/openPlatformHome/closeOrRestart',params)
            return res
        },
        
        // 全部企业
        async getAllEnterprise(context, params){
            let res = await request.get('/client/enterprise/allEnterprise',{params})
            return res
        },
        
    }
}