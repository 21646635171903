import request from '@/utils/request/index'
export default{
    namespaced:true,
    actions:{
        //个人认证服务
        async personalCertificationService(context, params){
            let res = await request.get('/openPlatform/openPlatformHome/personalCertificationService',{params})
            return res
        },
        //企业认证服务
        async enterpriseCertificationService(context, params){
            let res = await request.get('/openPlatform/openPlatformHome/enterpriseCertificationService',{params})
            return res
        },
        
        
    }
}