import Cookies from "js-cookie";
export async function initUserInfo(store) { //初始化用户信息
    let userId = Cookies.get(`user_id`)
    let token = Cookies.get(`admin_yq_token`)
    if (userId&&token) {
        let userRes =  await store.dispatch('user/getUserInfo', {userId});
        let companyRes = await store.dispatch('work/getIndexInfo', {userId,environment:0})
        if(userRes && companyRes){
            return {user:userRes.result,company:companyRes.result}
        }
        
    }
}
export async function userExit(){
    Cookies.remove('admin_yq_token')
    Cookies.remove('user_id')
    Cookies.remove('distributorIdentifier')

    window.localStorage.removeItem('admin_yq_token')
    window.localStorage.removeItem('user_id')
    
}