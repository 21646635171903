export default {

    data() {
        return {
            
        }
    },
    methods: {
        
    },
    computed: {
        ENV(){
            return this.$store.state.ENV
        },
        USER_INFO() {
            return this.$store.state.user.userInfo;
        },
        
        documentHeight(){
            return this.$store.state.documentHeight;
        },
        COMPANY_INFO(){
            return this.$store.state.work.company_info || {};
        },
        COMPANY_NUM(){
            return this.$store.state.work.company_info.enterpriseIdentifier || '';
        }
    },

}