export function textFormat(input) {
    //普通文本过滤器
    try {
        if (
            input === null ||
            input === undefined ||
            input === "" ||
            input === 'null' ||
            input === 'undefined'
        ) {
            return "-";
        }
        return input;
    } catch (err) {
        return input;
    }
}

let moment = require('moment');
export function startEndTime(arr){
    if(!arr || arr.length!=2){
        return ['','']
    }
    try {
        let start_time = moment(arr[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        let end_time = moment(arr[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        return [start_time,end_time]
    } catch (error) {
        return ['','']
    }
}

import { SignStateArr } from '../enum'
export function signStateFormat(value){
    try {
        let item = SignStateArr.find(el=>el.value==value)
        
        return item&&item.label || '-'
    } catch (error) {
        return '-'
    }
}

export function phoneEncryptFormat(value){
    try {
        let str = String(value)
        return str.slice(0,3)+'****'+str.slice(str.length-4,str.length)
    } catch (error) {
        return value || '-'
    }
}


export function date(timer) {
    var date = new Date(timer); //当前时间
    var year = date.getFullYear() //返回指定日期的年份
    var month = repair(date.getMonth() + 1); //月
    var day = repair(date.getDate()); //日
    var hours = repair(date.getHours()); //日
    var minutes = repair(date.getMinutes()); //日
    var seconds = repair(date.getSeconds()); //日
    var curTime = year + "-" + month+ "-" + day+ " " + hours+ ":" + minutes+ ":" + seconds
    function repair(i) {
        if (i >= 0 && i <= 9) {
        return "0" + i;
        } else {
        return i;
        }
    }
    return curTime
}