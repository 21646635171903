import Vue from 'vue'
import Vuex from 'vuex'
import user from './apis/user'
import work from './apis/work'
import worder from './apis/worder'
import identify from './apis/identify'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    BSERUL:'http://192.168.10.23:8090',
    ENV:0,//0-正式环境 1-测试环境
    documentHeight:''
  },
  getters: {
  },
  mutations: {
    SET_ENV(state,value){
      state.ENV = value
    },
    SET_DOCUMENT_HEIGHT(state,value){
      state.documentHeight = value
    }
  },
  actions: {
  },
  modules: {
    user,
    identify,
    work,
    worder
  }
})
