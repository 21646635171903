import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/main',
    name: 'work',
    component: () => import('../layout/mainLayout.vue'),
    redirect:"/main/index",
    children:[
      {
        path: 'index',
        name: 'index',
        component: () => import('../views/index/index.vue')
      },
      {
        path: 'app',
        name: 'app',
        component: () => import('../views/app/index.vue')
      },
      {
        path: 'signingProcess',
        name: 'signingProcess',
        component: () => import('../views/signingProcess/index.vue')
      },
      //个人认证服务
      {
        path: 'personal',
        name: 'personal',
        component: () => import('../views/authentication/personal/index.vue')
      },
      //企业认证服务
      {
        path: 'enterprise',
        name: 'enterprise',
        component: () => import('../views/authentication/enterprise/index.vue')
      },
      //我的工单
      {
        path:'workOrder',
        name:'workOrder',
        component: () => import('../views/workOrder/index.vue')
      },
      //创建工单
      {
        path:'creatOrder',
        name:'creatOrder',
        component: () => import('../views/workOrder/creatOrder.vue')
      },
      //工单详情
      {
        path:'workDetail',
        name:'workDetail',
        component: () => import('../views/workOrder/workDetail.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/notCertified',
    name: 'notCertified',
    component: () => import('../views/notCertified/index.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/index.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/index.vue')
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})



export default router
